import { navigate } from "svelte-routing";
import localStorageService from "../axios/localStorageService";
export const routes = [
  {
    path: "/",
  },
  {
    path: "/Workspaces",
  },
];

export const RouteProtector = () => {
  switch (window.location.pathname) {
    case "/":
      if (localStorageService.getToken()) navigate("/Workspaces");
      break;

    default:
      break;
  }
};
