<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { pannable } from "../../utils/pannable.js";
  import {create_history} from '../../utils/history'
  import {delete_history} from '../../utils/history'
  import { Fonts } from "../../utils/prepareAssets.js";
  import { tapout } from "../../utils/tapout.js";
  import Toolbar from "../../components/Toolbar.svelte";
  export let width;
  export let height;
  export let x;
  export const text = "text";
  export let y;
  export let pageScale = 1;
  export let lineHeight;
  export let size;
  export let fontFamily;
  export let editMode;
  export let charactersNumber;
  export let typeSelected;
  export let line;
  export let visibility;
  export let relation;
  let selected = false;
  const Families = Object.keys(Fonts);
  const Types = ["Text", "Check", "Email", "Numero", "BIC", "IBAN"];
  let _fontFamily = fontFamily;
  const numFormats = ["Default", "Mobile"];
  let numFormat = "Default";
  const dispatch = createEventDispatcher();
  let startX;
  let startY;
  let canvas;
  let operation = "";
  let directions = [];
  let _size = size;
  let _lineHeight = lineHeight;
  let editable;
  let dx = 0;
  let dy = 0;
  let dw = 0;
  let dh = 0;
  let highlight = false;

  async function render() {
    // use canvas to prevent img tag's auto resize
    editable.innerHTML = "Veuillez identifier ce champ de texte";
    // editable.focus();

    dispatch("update", {
      width: width * pageScale,
      height: height * pageScale,
      lines: extractLines(),
    });
  }
  function handlePanMove(event) {
    const _dx = (event.detail.x - startX) / pageScale;
    const _dy = (event.detail.y - startY) / pageScale;
    if (operation === "move") {
      dx = _dx;
      dy = _dy;
    } else if (operation === "scale") {
      if (directions.includes("left")) {
        dx = _dx;
        dw = -_dx;
      }
      if (directions.includes("top")) {
        dy = _dy;
        dh = -_dy;
      }
      if (directions.includes("right")) {
        dw = _dx;
      }
      if (directions.includes("bottom")) {
        dh = _dy;
      }
    }
  }
  function sanitize() {
    let weirdNode;
    while (
      (weirdNode = Array.from(editable.childNodes).find(
        (node) => !["#text", "BR"].includes(node.nodeName)
      ))
    ) {
      editable.removeChild(weirdNode);
    }
  }

  function handlePanEnd(event) {
    if (dx === 0 && dy === 0) {
      return editable.focus();
    }
    if (operation === "move") {
      dispatch("update", {
        x: x + dx,
        y: y + dy,
      });
      dx = 0;
      dy = 0;
    } else if (operation === "scale") {
      dispatch("update", {
        x: x + dx,
        y: y + dy,
        width: width + dw,
        height: height + dh,
      });
      dx = 0;
      dy = 0;
      dw = 0;
      dh = 0;
      directions = [];
    }
    operation = "";
    if (editMode == "EDIT") dispatch("mode", "MOVE");
    window.removeEventListener("keydown", onKeyDownTool, false);
  }

  function onFocusTool() {
    operation = "tool";
  }
  function handlePanStart(event) {
    selected = true;
    if (editMode == "MOVE" && selected) {
      window.addEventListener("keydown", onKeyDownTool, false);
    }
    startX = event.detail.x;
    startY = event.detail.y;
    if (event.detail.target === event.currentTarget) {
      return (operation = "move");
    }
    operation = "scale";
    directions = event.detail.target.dataset.direction.split("-");
  }
  function onDelete() {
    window.removeEventListener("keydown", onKeyDownTool, false);
    dispatch("delete");
  }
  function onChangeFont() {
    dispatch("update", {
      name: _fontFamily,
    });
  }
  function onChangeSize() {
    dispatch("update", {
      size: _size,
    });
  }
  function onChangeLineHeight() {
    dispatch("update", {
      name: _lineHeight,
    });
  }
  function onChangeType() {
    dispatch("update", {
      fieldType: typeSelected,
      numFormat: numFormat,
    });
  }
  function onChangeNumFormat() {
    dispatch("update", {
      numFormat: numFormat,
    });
  }

  function onChangeCharactersNumber() {
    dispatch("update", {
      charactersNumber: charactersNumber,
    });
  }

  function updateText(e) {
    editable.innerHTML = e.target.value;
    dispatch("update", {
      lines: extractLines(),
    });
  }
  function updateRelation(e){
    dispatch("update",{
      relation: e.target.value
    })
  }
  function onKeydown(e) {
    const childNodes = Array.from(editable.childNodes);
    if (e.keyCode === 13) {
      // prevent default adding div behavior
      e.preventDefault();
      const selection = window.getSelection();
      const focusNode = selection.focusNode;
      const focusOffset = selection.focusOffset;
      // the caret is at an empty line
      if (focusNode === editable) {
        editable.insertBefore(
          document.createElement("br"),
          childNodes[focusOffset]
        );
      } else if (focusNode instanceof HTMLBRElement) {
        editable.insertBefore(document.createElement("br"), focusNode);
      }
      // the caret is at a text line but not end
      else if (focusNode.textContent.length !== focusOffset) {
        document.execCommand("insertHTML", false, "<br>");
        // the carat is at the end of a text line
      } else {
        let br = focusNode.nextSibling;
        if (br) {
          editable.insertBefore(document.createElement("br"), br);
        } else {
          br = editable.appendChild(document.createElement("br"));
          br = editable.appendChild(document.createElement("br"));
        }
        // set selection to new line
        selection.collapse(br, 0);
      }
    }
    dispatch("update", {
      lines: extractLines(),
    });
  }

  function extractLines() {
    const nodes = editable.childNodes;
    let lineText = "";
    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index];
      lineText += node.textContent;
    }
    return lineText;
  }

  function onBlur() {
    if (editMode == "EDIT" && highlight) {
      if (operation !== "edit" || operation === "tool") return;
      editable.blur();
      sanitize();

      dispatch("update", {
        lines: extractLines(),
        x: x + dx,
        y: y + dy,
        width: width + dw,
        height: height + dh,
      });
      dx = 0;
      dy = 0;
      dw = 0;
      dh = 0;
      operation = "";
      highlight = false;
    } else if (editMode == "MOVE" && selected) {
      editable.blur();
      sanitize();

      dispatch("update", {
        lines: extractLines(),
        x: x + dx,
        y: y + dy,
        width: width + dw,
        height: height + dh,
      });
      dx = 0;
      dy = 0;
      dw = 0;
      dh = 0;
      operation = "";
      highlight = false;
      selected = false;
    }
    window.removeEventListener("keydown", onKeyDownTool, false);
    window.onscroll = function () {};
  }
  function onFocus() {
    if (editMode == "EDIT") {
      highlight = true;
      operation = "edit";
    }
  }
  function onFocusOut() {
    operation = "";
  }
  function onCopyTool(ev) {
    // function to check the detection
    ev = ev || window.event; // Event object 'ev'
    var key = ev.which || ev.keyCode; // Detecting keyCode

    // Detecting Ctrl
    var ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false;

    // If key pressed is V and if ctrl is true.
    if (key == 86 && ctrl) {
      // print in console.
      let timestampId = Date.now();
      dispatch("copy", {
        id: timestampId,
        type: "image",
        width: width  /pageScale,
        height: height /pageScale,
        x: 0,
        y: 0,
        size: _size,
        lineHeight: _lineHeight,
        fieldType: typeSelected,
        charactersNumber: charactersNumber,
        line: line,
      });
    } 
  }

  function onKeyDownTool(e) {
    onCopyTool(e);
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
    switch (e.key) {
      case "ArrowDown":
        dispatch("update", {
          y: y + 1,
        });
        break;
      case "ArrowUp":
        dispatch("update", {
          y: y - 1,
        });
        break;
      case "ArrowRight":
        dispatch("update", {
          x: x + 1,
        });
        break;
      case "ArrowLeft":
        dispatch("update", {
          x: x - 1,
        });
        break;
      default:
        break;
    }
  }
  async function onBlurTool() {
    if (operation !== "tool" || operation === "edit") return;
    dispatch("update", {
      lines: extractLines(),
      lineHeight: _lineHeight,
      size: _size,
      fontFamily: _fontFamily,
    });
    operation = "";
  }

  function onChangeVisibility(){
    dispatch("update",{
      visibility: visibility
    })
  }

  onMount(render);
</script>

{#if operation && editMode == "MOVE"}
  <div
    class="absolute select-none"
    style="width:{280 * 2.13}px;transform: translate(0px,
    {y + dy + size - lineHeight * 2}px);"
  >
    <hr style="border-top: 0.1px dashed #888;" />
  </div>

  <div class="ruler_container">
    <ul class="ruler-x">
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
    </ul>
    <ul class="ruler-y">
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
    </ul>
  </div>
{/if}
{#if operation && editMode == "EDIT"}
  <Toolbar>
    <div
      use:tapout
      on:tapout={onBlurTool}
      on:mousedown={onFocusTool}
      on:touchstart={onFocusTool}
      class="h-full flex-col justify-around items-center p-5 bg-gray-300 border-b
    border-gray-400"
    >
      <div class="flex justify-center items-center">
        <button
          on:click={() => dispatch("mode", "MOVE")}
          type="button"
          class="py-1 px-3 mr-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-arrows-move"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 9l3 3l-3 3" />
            <path d="M15 12h6" />
            <path d="M6 9l-3 3l3 3" />
            <path d="M3 12h6" />
            <path d="M9 18l3 3l3 -3" />
            <path d="M12 15v6" />
            <path d="M15 6l-3 -3l-3 3" />
            <path d="M12 3v6" />
          </svg>
        </button>
        <div class="mr-2 flex items-center">
          <div style="display:flex;justify-content:center;margin: 5px;">
            <img src="/line_height.svg" class="w-4 mr-2" alt="Line height" />
            <p>Hauteur de la ligne</p>
          </div>
          <input
            type="number"
            min="1"
            max="10"
            step="0.1"
            class="h-6 w-12 text-center flex-shrink-0 rounded-sm"
            bind:value={_lineHeight}
            on:change={onChangeLineHeight}
          />
        </div>
        <div class="mr-2 flex items-center">
          <div style="display:flex;justify-content:center;margin: 5px;">
            <img src="/text.svg" class="w-4 mr-2" alt="Font size" />
            <p>Taille du texte</p>
          </div>
          <input
            type="number"
            min="1"
            max="120"
            step="1"
            class="h-6 w-12 text-center flex-shrink-0 rounded-sm"
            bind:value={_size}
            on:change={onChangeSize}
          />
        </div>

        <div class="mr-2 flex items-center">
          <div style="display:flex;justify-content:center;margin: 5px;">
            <img src="/text-family.svg" class="w-4 mr-2" alt="Font family" />
            <p>Famille de polices</p>
          </div>
          <div class="relative w-32 md:w-40">
            <select
              bind:value={_fontFamily}
              on:change={onChangeFont}
              class="font-family"
            >
              {#each Families as family}
                <option value={family}>{family}</option>
              {/each}
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex
            items-center px-2 text-gray-700"
            >
              <img src="/arrow-right-svgrepo-com.svg" class="w-4 mr-2" alt="" />
            </div>
          </div>
        </div>
        <div class="mr-2 flex items-center">
          <div style="display:flex;justify-content:center;margin: 5px;">
            <!-- <img src="/type.svg" class="w-4 mr-2" alt="type" /> -->
            <p>Type de contenu de champ</p>
          </div>
          <div class="relative w-20 md:w-20">
            <select
              bind:value={typeSelected}
              on:change={onChangeType}
              class="font-family"
            >
              {#each Types as type}
                <option value={type}>{type}</option>
              {/each}
            </select>
          </div>
        </div>
        {#if typeSelected == "Numero"}
          <div class="mr-2 flex items-center">
            <div style="display:flex;justify-content:center;margin: 5px;">
              <img src="/format-svgrepo-com.svg" class="w-4 mr-2" alt="Model" />
              <p>Model du Numero</p>
            </div>
            <select
              class="font-family"
              bind:value={numFormat}
              on:change={onChangeNumFormat}
            >
              {#each numFormats as format}
                <option value={format}>{format}</option>
              {/each}
            </select>
          </div>
        {/if}
        <div class="mr-2 flex items-center">
          <div style="display:flex;justify-content:center;margin: 5px;">
            <img
              src="/width-svgrepo-com.svg"
              class="w-4 mr-2"
              alt="CharacterNumber"
            />
            <p>Nombre de caractères autorisés</p>
          </div>
          <input
            type="number"
            min="0"
            step="1"
            class="h-6 w-12 text-center flex-shrink-0 rounded-sm"
            bind:value={charactersNumber}
            on:change={onChangeCharactersNumber}
          />
        </div>
        <div class="mr-2 flex items-center">
          <div style="display:flex;justify-content:center;margin: 5px;">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-browser-check"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="4" y="4" width="16" height="16" rx="1" />
              <path d="M4 8h16" />
              <path d="M8 4v4" />
              <path d="M9.5 14.5l1.5 1.5l3 -3" />
            </svg>
            <p>Visibilité</p>
          </div>
          <select bind:value={visibility} on:change={onChangeVisibility} class="font-family">
            <option value="Oui">Oui</option>
            <option value="Non">Non</option>
          </select>
        </div>
      </div>
      <div class="flex justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-alphabet-latin"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M6 10h2a2 2 0 0 1 2 2v5h-3a2 2 0 1 1 0 -4h3" />
          <path d="M14 7v10" />
          <rect x="14" y="10" width="5" height="7" rx="2" />
        </svg>
        <input
          class="shadow appearance-none border rounded h-6 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="text"
          type="text"
          bind:value={line}
          on:change={updateText}
        />
      </div>
      <div class="flex justify-center items-center m-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-circles-relation"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M9.183 6.117a6 6 0 1 0 4.511 3.986" />
          <path d="M14.813 17.883a6 6 0 1 0 -4.496 -3.954" />
        </svg>
        <input
          class="shadow appearance-none border rounded h-6 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="text"
          type="text"
          bind:value={relation}
          on:change={updateRelation}
        />
      </div>
    </div>
  </Toolbar>
{/if}

<div
  class="absolute left-0 top-0 select-none"
  style="width: {width + dw}px; height: {height +
    dh}px; transform: translate({x + dx}px,
  {y + dy}px);"
  use:tapout
  on:tapout={onBlur}
>
  <div
    use:pannable
    on:panstart={handlePanStart}
    on:panmove={handlePanMove}
    on:panend={handlePanEnd}
    class="absolute w-full h-full {editMode === 'MOVE'
      ? 'cursor-move'
      : 'cursor-crosshair'}"
    class:cursor-grabbing={operation === "move"}
    class:operation
  >
    <div
      data-direction="left"
      class="resize-border h-full w-1 left-0 top-0 border-l cursor-ew-resize"
    />
    <div
      data-direction="top"
      class="resize-border w-full h-1 left-0 top-0 border-t cursor-ns-resize"
    />
    <div
      data-direction="bottom"
      class="resize-border w-full h-1 left-0 bottom-0 border-b cursor-ns-resize"
    />
    <div
      data-direction="right"
      class="resize-border h-full w-1 right-0 top-0 border-r cursor-ew-resize"
    />
    {#if operation}
      <div
        data-direction="left-top"
        class="dots left-0 top-0 cursor-nwse-resize transform
   -translate-x-1/2 -translate-y-1/2 "
      />
      <div
        data-direction="right-top"
        class="dots right-0 top-0 cursor-nesw-resize transform
   translate-x-1/2 -translate-y-1/2 "
      />
      <div
        data-direction="left-bottom"
        class="dots left-0 bottom-0 cursor-nesw-resize transform
   -translate-x-1/2 translate-y-1/2 "
      />
      <div
        data-direction="right-bottom"
        class="dots right-0 bottom-0 cursor-nwse-resize transform
   translate-x-1/2 translate-y-1/2 "
      />
      <div
        on:click={onDelete}
        class="absolute left-0 top-0 right-0 w-12 h-12 m-auto rounded-full bg-white
   cursor-pointer transform -translate-y-1/2 md:scale-25"
      >
        <img class="w-full h-full" src="/delete.svg" alt="delete object" />
      </div>
    {/if}

    <div
      bind:this={editable}
      on:keydown={onKeydown}
      on:focus={onFocus}
      contenteditable={editMode == "EDIT" ? "true" : "false"}
      spellcheck="false"
      class="outline-none"
      style="font-size: {_size}px; font-family: '{_fontFamily}', serif;
        {highlight ? 'color: red;' : ''}
        white-space:nowrap; overflow: hidden; white-space: nowrap;
    line-height: {_lineHeight}; {editMode == 'MOVE'
        ? 'pointer-events:none;-webkit-user-select: text;  user-select: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;'
        : ''} "
    />
  </div>
</div>

<style>
  .operation {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .resize-border {
    @apply absolute border-dashed border-gray-600;
  }

  .ruler-x {
    height: var(--ruler2-h);
    inset-block-start: 0;
    inset-inline-start: calc(var(--ruler-unit) * var(--ruler2-space));
    opacity: var(--ruler-x);
    width: 600px;
  }
  .ruler-y {
    flex-direction: column;
    height: 820px;
    inset-block-start: calc(var(--ruler-unit) * var(--ruler2-space));
    inset-inline-start: 0;
    opacity: var(--ruler-y);
    width: var(--ruler2-h);
  }
  .ruler_container {
    --ruler-num-c: #888;
    --ruler-num-fz: 10px;
    --ruler-num-pi: 0.75ch;
    --ruler-unit: 1px;
    --ruler-x: 1;
    --ruler-y: 1;

    --ruler1-bdw: 1px;
    --ruler1-c: #bbb;
    --ruler1-h: 8px;
    --ruler1-space: 5;

    --ruler2-bdw: 1px;
    --ruler2-c: #bbb;
    --ruler2-h: 20px;
    --ruler2-space: 50;

    background-attachment: fixed;
    background-image: linear-gradient(
        90deg,
        var(--ruler1-c) 0 var(--ruler1-bdw),
        transparent 0
      ),
      linear-gradient(90deg, var(--ruler2-c) 0 var(--ruler2-bdw), transparent 0),
      linear-gradient(0deg, var(--ruler1-c) 0 var(--ruler1-bdw), transparent 0),
      linear-gradient(0deg, var(--ruler2-c) 0 var(--ruler2-bdw), transparent 0);
    background-position: 0 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: calc(
          var(--ruler-unit) * var(--ruler1-space) * var(--ruler-x)
        )
        var(--ruler1-h),
      calc(var(--ruler-unit) * var(--ruler2-space) * var(--ruler-x))
        var(--ruler2-h),
      var(--ruler1-h)
        calc(var(--ruler-unit) * var(--ruler1-space) * var(--ruler-y)),
      var(--ruler2-h)
        calc(var(--ruler-unit) * var(--ruler2-space) * var(--ruler-y));
  }
  .dots {
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
    border-radius: 9999px;
  }
</style>
