function get_session_history() {
  return JSON.parse(sessionStorage.getItem("prev"));
}

function set_session_history(history) {
  sessionStorage.setItem("prev", JSON.stringify(history));
}

export function create_history(data) {
  let history = get_session_history();

  if (history && 20 > history.data.length > 0) {
    if (
      JSON.stringify(history.data[history.data.length - 1]) !==
      JSON.stringify(data)
    ) {
      history.data.push(data);
      set_session_history(history);
    }
  } else {
    set_session_history({ data: [data] });
  }
}

export function delete_history() {
  let history = get_session_history();
  if (history.data.length > 0) {
    let deleted = history.data.pop();
    set_session_history(history);
    return history.data[history.data.length - 1];
  }
}

