<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import Tailwind from "../../Tailwind.svelte";
  import PDFPage from "../../components/PDFPage.svelte";
  import Image from "./Image.svelte";
  import DrawingCanvas from "../../components/DrawingCanvas.svelte";
  import prepareAssets, { fetchFont } from "../../utils/prepareAssets.js";
  import { v4 as uuidv4 } from "uuid";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "../../utils/asyncReader.js";
  import { ggID } from "../../utils/helper.js";
  import axios from "axios";
  import SimpleCrypto from "simple-crypto-js";
  import { navigate } from "svelte-routing";
  import { create_history, delete_history } from "../../utils/history";
  const genID = ggID();
  let pdfFile;
  let pdfName = "";
  let pages = [];
  let pagesScale = [];
  let allObjects = [];
  let currentFont = "Times-Roman";
  let focusId = null;
  let selectedPageIndex = -1;
  let saving = false;
  let addingDrawing = false;
  let currentWorkspace;
  let editMode = "MOVE";
  // for test purpose
  onMount(async () => {
    let simpleCrypto = new SimpleCrypto("process.env.SECRET_KEY");
    let workspacecrypted = localStorage.getItem("-");
    currentWorkspace = simpleCrypto.decrypt(workspacecrypted);
    try {
      const res = await fetch("/test.pdf");
      const pdfBlob = await res.blob();
      await addPDF(pdfBlob);
      selectedPageIndex = 0;
      setTimeout(() => {
        fetchFont(currentFont);
        prepareAssets();
      }, 5000);
      // const imgBlob = await (await fetch("/test.jpg")).blob();
      // addImage(imgBlob);
      // addTextField("測試!");
      // addDrawing(200, 100, "M30,30 L100,50 L50,70", 0.5);
    } catch (e) {
      console.log(e);
    }
    sessionStorage.clear()
    window.addEventListener("keydown", onKeyDownTool, false);
  });

  function onKeyDownTool(ev) {
    // function to check the detection
    ev = ev || window.event; // Event object 'ev'
    var key = ev.which || ev.keyCode; // Detecting keyCode

    // Detecting Ctrl
    var ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false;

    if (key == 90 && ctrl) {
      let lastModification = delete_history();
      lastModification && updateToPreviousObjects(lastModification);
    }
  }

  function updateToPreviousObjects(prevObjects) {
    allObjects = prevObjects
  }
  async function onUploadPDF(e) {
    const files = e.target.files || (e.dataTransfer && e.dataTransfer.files);
    const file = files[0];
    if (!file || file.type !== "application/pdf") return;
    selectedPageIndex = -1;
    try {
      await addPDF(file);
      selectedPageIndex = 0;
    } catch (e) {
      console.log(e);
    }
  }
  async function addPDF(file) {
    try {
      const pdf = await readAsPDF(file);
      pdfFile = file;
      const numPages = pdf.numPages;
      pages = Array(numPages)
        .fill()
        .map((_, i) => pdf.getPage(i + 1));
      allObjects = pages.map(() => []);
      pagesScale = Array(numPages).fill(1);
    } catch (e) {
      console.log("Failed to add pdf.");
      throw e;
    }
  }
  async function onUploadImage() {
    if (selectedPageIndex >= 0) {
      addImage();
    }
  }
  async function addImage() {
    try {
      fetchFont(currentFont);
      const id = genID();
      const object = {
        id,
        type: "image",
        width: 150,
        height: 50,
        x: 0,
        y: 0,
        size: 14,
        lineHeight: 1,
        fontFamily: currentFont,
        fieldType: "Text",
        charactersNumber: 0,
        visibility:"Oui",
        relation: ""
      };
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }
  function onAddTextField() {
    if (selectedPageIndex >= 0) {
      addTextField();
    }
  }
  function addTextField(text = "New Text Field") {
    const id = genID();
    fetchFont(currentFont);
    const object = {
      id,
      text,
      type: "text",
      size: 16,
      width: 0, // recalculate after editing
      lineHeight: 1.4,
      fontFamily: currentFont,
      x: 0,
      y: 0,
    };
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }
  function onAddDrawing() {
    if (selectedPageIndex >= 0) {
      addingDrawing = true;
    }
  }
  function addDrawing(originWidth, originHeight, path, scale = 1) {
    const id = genID();
    const object = {
      id,
      path,
      type: "drawing",
      x: 0,
      y: 0,
      originWidth,
      originHeight,
      width: originWidth * scale,
      scale,
    };
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }
  function selectFontFamily(event) {
    const name = event.detail.name;
    fetchFont(name);
    currentFont = name;
  }
  function selectPage(index) {
    selectedPageIndex = index;
  }
  function updateMode(mode) {
    editMode = mode;
  }
  function updateObject(objectId, payload) {
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex == selectedPageIndex
        ? objects.map((object) =>
            object.id === objectId ? { ...object, ...payload } : object
          )
        : objects
    );
    create_history(allObjects);
  }
  function deleteObject(objectId) {
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex == selectedPageIndex
        ? objects.filter((object) => object.id !== objectId)
        : objects
    );
  }
  function onMeasure(scale, i) {
    pagesScale[i] = scale;
  }
  function onChangePdfName() {
    /* console.log(pdfName); */
  }
  function deleteCopyImage(id) {
    deleteObject(id);
  }
  async function copyImage(obj) {
    const { id,width, height, size, lineHeight, line, charactersNumber,fieldType } =
      obj.detail;
    try {
      fetchFont(currentFont);
      const object = {
        id: id,
        type: "image",
        width: width,
        height: height,
        x: 0,
        y: 0,
        size: size,
        lineHeight: lineHeight,
        fontFamily: currentFont,
        fieldType: fieldType,
        charactersNumber: charactersNumber,
        lines: line,
      };
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }

  async function savePDF() {
    if (!pdfFile || saving || !pages.length) return;
    saving = true;

    /* await save(pdfFile, allObjects, pdfName, pagesScale); */
    const data = allObjects;
    const file = new FormData();
    file.append("file", pdfFile);
    file.append("workspace_id", currentWorkspace.id);
    let uuid = uuidv4();
    axios
      .post(
        "https://staging.ws.machine-editique.cloud.geoprod.com/editique_pdf/upload",
        file,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then((res) => {
        if (pdfName !== "") {
          axios
            .post(
              "https://staging.ws.machine-editique.cloud.geoprod.com/editique_pdf/setFields",
              {
                data: data,
                fileurl: res.data.file_url,
                idModel: uuid,
                modelName: pdfName,
                workspace_id: currentWorkspace.id,
              }
            )
            .then((res) => {
              saving = false;
              navigate("/HomeWorkspace");
            })
            .catch((error) => console.log(error));
        } else {
          alert("Model name not found");
        }
      })
      .catch((err) => console.log(err));
  }
</script>

<svelte:window
  on:dragenter|preventDefault
  on:dragover|preventDefault
  on:drop|preventDefault={onUploadPDF}
/>
<Tailwind />
<main class="flex flex-col items-center py-16 bg-gray-100 min-h-screen">
  <div
    class="fixed z-10 top-0 left-0 right-0 h-12 flex justify-center items-center
    bg-gray-200 border-b border-gray-300"
  >
    <button
      on:click={() => navigate("/HomeWorkspace")}
      type="button"
      class="py-1 px-3 mr-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-home-dot"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M19 12h2l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h5" />
        <circle cx="19" cy="19" r="3" />
        <path d="M9 21v-6a2 2 0 0 1 2 -2h2c.641 0 1.212 .302 1.578 .771" />
      </svg></button
    >
    <input
      type="file"
      name="pdf"
      id="pdf"
      on:change={onUploadPDF}
      class="hidden"
    />
    <input id="image" name="image" class="hidden" on:click={onUploadImage} />
    <label
      class="whitespace-no-wrap bg-blue-500 hover:bg-blue-700 text-white
      font-bold py-1 px-3 md:px-4 rounded mr-3 cursor-pointer md:mr-4"
      for="pdf"
    >
      Choisir PDF
    </label>
    <div
      class="py-1 px-3 mr-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
    >
      <label
        class="flex items-center justify-center h-full w-8
        cursor-pointer"
        for="image"
        class:cursor-not-allowed={selectedPageIndex < 0}
        class:bg-gray-500={selectedPageIndex < 0}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-text-plus"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M19 10h-14" />
          <path d="M5 6h14" />
          <path d="M14 14h-9" />
          <path d="M5 18h6" />
          <path d="M18 15v6" />
          <path d="M15 18h6" />
        </svg>
      </label>
      <!-- <label
        class="flex items-center justify-center h-full w-8 hover:bg-gray-500
        cursor-pointer"
        for="text"
        class:cursor-not-allowed={selectedPageIndex < 0}
        class:bg-gray-500={selectedPageIndex < 0}
        on:click={onAddTextField}
      >
        <img src="notes.svg" alt="An icon for adding text" />
      </label>
      <label
        class="flex items-center justify-center h-full w-8 hover:bg-gray-500
        cursor-pointer"
        on:click={onAddDrawing}
        class:cursor-not-allowed={selectedPageIndex < 0}
        class:bg-gray-500={selectedPageIndex < 0}
      >
        <img src="gesture.svg" alt="An icon for adding drawing" />
      </label> -->
    </div>
    <div class="justify-center mr-3 md:mr-4 w-full max-w-xs hidden md:flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-writing-sign"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M3 19c3.333 -2 5 -4 5 -6c0 -3 -1 -3 -2 -3s-2.032 1.085 -2 3c.034 2.048 1.658 2.877 2.5 4c1.5 2 2.5 2.5 3.5 1c.667 -1 1.167 -1.833 1.5 -2.5c1 2.333 2.333 3.5 4 3.5h2.5"
        />
        <path
          d="M20 17v-12c0 -1.121 -.879 -2 -2 -2s-2 .879 -2 2v12l2 2l2 -2z"
        />
        <path d="M16 7h4" />
      </svg>
      <input
        placeholder="Nom du Model"
        type="text"
        class="flex-grow bg-transparent"
        bind:value={pdfName}
        on:change={onChangePdfName}
      />
    </div>
    <button
      on:click={() => (editMode = "MOVE")}
      type="button"
      style={editMode === "MOVE" ? "box-shadow: inset 0 0 10px #f8a100;" : ""}
      class="py-1 px-3 mr-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-arrows-move"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M18 9l3 3l-3 3" />
        <path d="M15 12h6" />
        <path d="M6 9l-3 3l3 3" />
        <path d="M3 12h6" />
        <path d="M9 18l3 3l3 -3" />
        <path d="M12 15v6" />
        <path d="M15 6l-3 -3l-3 3" />
        <path d="M12 3v6" />
      </svg>
    </button>
    <button
      on:click={() => (editMode = "EDIT")}
      type="button"
      class="py-1 px-3 mr-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      style={editMode === "EDIT" ? "box-shadow: inset 0 0 10px #f8a100;" : ""}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-file-pencil"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        <path
          d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
        />
        <path d="M10 18l5 -5a1.414 1.414 0 0 0 -2 -2l-5 5v2h2z" />
      </svg>
    </button>
    <button
      on:click={savePDF}
      class="w-25 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3
      md:px-4 mr-3 md:mr-4 rounded"
      class:cursor-not-allowed={pages.length === 0 || saving || !pdfFile}
      class:bg-blue-700={pages.length === 0 || saving || !pdfFile}
    >
      {saving ? "..." : "Enregistrer"}
    </button>
  </div>
  {#if addingDrawing}
    <div
      transition:fly={{ y: -200, duration: 500 }}
      class="fixed z-10 top-0 left-0 right-0 border-b border-gray-300 bg-white
      shadow-lg"
      style="height: 50%;"
    >
      <DrawingCanvas
        on:finish={(e) => {
          const { originWidth, originHeight, path } = e.detail;
          let scale = 1;
          if (originWidth > 500) {
            scale = 500 / originWidth;
          }
          addDrawing(originWidth, originHeight, path, scale);
          addingDrawing = false;
        }}
        on:cancel={() => (addingDrawing = false)}
      />
    </div>
  {/if}
  {#if pages.length}
    <div class="flex justify-center px-5 w-full md:hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-writing"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M20 17v-12c0 -1.121 -.879 -2 -2 -2s-2 .879 -2 2v12l2 2l2 -2z"
        />
        <path d="M16 7h4" />
        <path d="M18 19h-13a2 2 0 1 1 0 -4h4a2 2 0 1 0 0 -4h-3" />
      </svg>
      <input
        placeholder="Nom du Model"
        type="text"
        class="flex-grow bg-transparent"
        bind:value={pdfName}
        on:change={onChangePdfName}
      />
    </div>
    <div class="w-full">
      {#each pages as page, pIndex (page)}
        <div
          class="p-5 w-full flex flex-col items-center overflow-hidden"
          on:mousedown={() => selectPage(pIndex)}
          on:touchstart={() => selectPage(pIndex)}
        >
          <div
            class="relative shadow-lg {editMode === 'MOVE'
              ? 'cursor-move'
              : 'cursor-crosshair'}"
            class:shadow-outline={pIndex === selectedPageIndex}
          >
            <PDFPage
              on:measure={(e) => onMeasure(e.detail.scale, pIndex)}
              {page}
            />
            <div
              class="absolute top-0 left-0 transform origin-top-left"
              style="transform: scale({pagesScale[
                pIndex
              ]}); touch-action: none; "
            >
              {#each allObjects[pIndex] as object (object.id)}
                {#if object.type === "image"}
                  <Image
                    on:update={(e) => updateObject(object.id, e.detail)}
                    on:delete={() => deleteObject(object.id)}
                    x={object.x}
                    y={object.y}
                    width={object.width}
                    size={object.size}
                    lineHeight={object.lineHeight}
                    height={object.height}
                    fontFamily={object.fontFamily}
                    pageScale={pagesScale[pIndex]}
                    objectIdtest={object.id}
                    relation={object.relation}
                    {editMode}
                    typeSelected={object.fieldType}
                    charactersNumber={object.charactersNumber}
                    line={object.lines}
                    visibility={object.visibility}
                    on:mode={(e) => updateMode(e.detail)}
                    on:copy={(obj) => copyImage(obj)}
                    on:deletecopy={(obj) => deleteCopyImage(obj.detail)}
                  />
                  <!--  {:else if object.type === "text"}
                  <Text
                    on:update={(e) => updateObject(object.id, e.detail)}
                    on:delete={() => deleteObject(object.id)}
                    on:selectFont={selectFontFamily}
                    text={object.text}
                    x={object.x}
                    y={object.y}
                    size={object.size}
                    lineHeight={object.lineHeight}
                    fontFamily={object.fontFamily}
                    pageScale={pagesScale[pIndex]}
                  />
                {:else if object.type === "drawing"}
                  <Drawing
                    on:update={(e) => updateObject(object.id, e.detail)}
                    on:delete={() => deleteObject(object.id)}
                    path={object.path}
                    x={object.x}
                    y={object.y}
                    width={object.width}
                    originWidth={object.originWidth}
                    originHeight={object.originHeight}
                    pageScale={pagesScale[pIndex]}
                  /> -->
                {/if}
              {/each}
            </div>
          </div>
        </div>
      {/each}
    </div>
  {:else}
    <div class="w-full flex-grow flex justify-center items-center">
      <span class=" font-bold text-3xl text-gray-500">Drag something here</span>
    </div>
  {/if}
</main>
