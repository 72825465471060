<script>
  import { onMount } from "svelte";
  import { Router, Route, navigate } from "svelte-routing";
  import App from "./pages/App/App.svelte";
  import AppUrl from "./pages/AppUrl/AppUrl.svelte";
  import Home from "./pages/Home.svelte";
  import Login from "./pages/Login/Login.svelte";
  import Selection from "./pages/Selection/Selection.svelte";
  import Workspaces from "./pages/Workspaces/Workspaces.svelte";
  import { RouteProtector } from "./utils/routes/routes";
  export let url = "";

  onMount(() => {
    RouteProtector();
  });
</script>

<Router {url}>
  <Route path="/" component={Login} />
  <Route path="/Workspaces" component={Workspaces} />
  <Route path="/HomeWorkspace" component={Home} />
  <Route path="/Workspace" component={App} />
  <Route path="/Select" component={Selection} />
  <Route path="/:id" let:params>
    <AppUrl id={params.id} />
  </Route>
</Router>
